/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Select, Modal, DatePicker, Switch, List} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';

//local imports
import {convertToMomentObj} from '../../../utils/locale/chronos';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import PollGrapher from './PollGrapher';
import {
  UTILITY_GREEN,
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import apiInstance from '../../../network/axios';
import {PollViewerStyles as styles} from '../styles';
import {CircleButton} from '../../../atoms';

interface IPollViewer {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  executivePrivilege: boolean;
  pollObject: {
    title?: string;
    message?: string;
    expires?: string;
    creator?: {
      role?: 'SUPER_ADMIN' | 'ADMIN' | 'OWNER' | 'BELL';
    };
    id?: string;
    neighborhood?: string[];
  };
}

const PollViewer = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  executivePrivilege,
  pollObject,
  visible,
}: IPollViewer) => {
  let randomColor = 0;
  const byWho =
    pollObject?.creator.role === 'SUPER_ADMIN'
      ? 'Por Portos'
      : 'Por Administrador';

  const title = pollObject?.title ?? '';
  const question = pollObject?.message ?? '';

  const [loading, setLoading] = useState<boolean>(executivePrivilege);
  const [error, setError] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<any>(
    convertToMomentObj(pollObject.expires) || null,
  );
  const [neighborhoodIds, setNeighborhoodIds] = useState<string[]>(
    pollObject?.neighborhood ?? [],
  );
  const [hoods, setHoods] = useState<any[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [pollDetail, setPollDetail] = useState<any>();
  const [showVoters, setShowVoters] = useState<boolean>(false);

  useEffect(() => {
    retrievePollData();
  }, []);

  const onSelectHood = (value) => {
    setNeighborhoodIds(value);
  };

  const onChangeDate = (date) => {
    setExpirationDate(date);
  };

  const retrievePollData = async () => {
    try {
      const url = `/messages/${pollObject?.id ?? ''}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setPollDetail(result);
      if (executivePrivilege) {
        retrieveHoodsData();
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const retrieveHoodsData = async () => {
    try {
      const url = '/neighborhoods';
      const response = await apiInstance.get(url);
      const result = response.data;
      setHoods(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeletePoll = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este mensaje?')) {
      setLoading(true);
      deletePoll();
    }
  };

  const deletePoll = async () => {
    try {
      const url = `/messages/${pollObject?.id ?? ''}`;
      await apiInstance.delete(url);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Descartar los cambios realizados?')) {
        setEditMode(!editMode);
        setExpirationDate(convertToMomentObj(pollObject?.expires));
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  const toggleVotedBy = () => {
    setShowVoters(!showVoters);
  };

  const validateEdit = () => {
    if (!executivePrivilege) {
      if (byWho === 'Por Portos') {
        alert('No es posible editar este mensaje');
        return;
      }
    }

    if (byWho === 'Por Portos') {
      alert('No es posible editar este mensaje');
      return;
    }
    if (!title) {
      alert('Ingrese título para continuar');
      return;
    }
    if (!question) {
      alert('Ingrese mensaje para continuar');
      return;
    }

    if (executivePrivilege) {
      if (neighborhoodIds.length === 0) {
        alert('Ingrese vecindarios para continuar');
        return;
      }
    }

    setLoading(true);
    putEditPoll();
  };

  const putEditPoll = async () => {
    try {
      const url = `/messages/${pollObject.id}`;
      let body = {};
      if (!expirationDate) {
        body = {
          expires: null,
        };
      } else {
        body = {
          expires: expirationDate.toISOString(),
        };
      }

      if (executivePrivilege) {
        body = {
          ...body,
          ...{neighborhood: neighborhoodIds},
        };
      }

      await apiInstance.put(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const whichRandomColor = () => {
    randomColor++;
    if (randomColor >= 5) randomColor = 0;
  };

  return (
    <Modal
      centered
      open={visible}
      onOk={validateEdit}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      width={'600px'}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : executivePrivilege ? (
          <Row>
            <Col span={2}>ç</Col>
            <Col span={22}>
              <CircleButton
                variant="view"
                activeColor={UTILITY_GREEN}
                disabled={pollDetail?.isAnonymous || loading}
                onClick={toggleVotedBy}
                clickedMode={showVoters}
              />
              <CircleButton
                variant="edit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validateEdit}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={2}>
              {byWho === 'Por Portos' ? null : (
                <CircleButton
                  variant="del"
                  activeColor={UTILITY_RED}
                  disabled={loading}
                  onClick={confirmDeletePoll}
                />
              )}
            </Col>
            <Col span={22}>
              <CircleButton
                variant="view"
                activeColor={UTILITY_GREEN}
                disabled={pollDetail?.isAnonymous || loading}
                onClick={toggleVotedBy}
                clickedMode={showVoters}
              />
              {byWho === 'Por Portos' ? null : (
                <CircleButton
                  variant="edit"
                  activeColor={UTILITY_YELLOW}
                  disabled={loading}
                  onClick={toggleEditMode}
                  clickedMode={editMode}
                />
              )}
              {byWho === 'Por Portos' ? null : (
                <CircleButton
                  variant="save"
                  activeColor={UTILITY_BLUE}
                  disabled={!editMode ? true : loading}
                  onClick={validateEdit}
                />
              )}
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            {byWho === 'Por Portos' ? (
              <FeatureIcon feature="super-admin" />
            ) : (
              <FeatureIcon feature="polls" />
            )}
          </Col>
          <Col xs={19} sm={20} md={21} style={styles.titleContainer}>
            <Row>
              <Col span={24} style={styles.title}>
                {title ?? 'Encuesta sin título'}
              </Col>
              <Col span={24} style={styles.byWho}>
                {byWho}
              </Col>
            </Row>
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : showVoters ? (
        <Row>
          <Col span={24}>
            {pollDetail?.choices.length > 0
              ? pollDetail?.choices.map((item: any, index: number) => (
                  <div key={index}>
                    <div key={index} style={styles.countContainer}>
                      <h3>{item.text}</h3>
                      <h4>{'Votos: ' + item.count}</h4>
                    </div>
                    {item.votes.length > 0 ? (
                      item.votes.map((votesItem, votesIndex) => {
                        if (pollDetail?.readBy.length > 0) {
                          return (
                            <List
                              key={votesIndex}
                              locale={{emptyText: 'Sin Registros'}}
                              bordered
                              style={styles.list}
                              dataSource={pollDetail?.readBy}
                              renderItem={(itemInList: any) => {
                                const statusIcon = (
                                  <FeatureIcon
                                    feature="user"
                                    randomColor={randomColor}
                                  />
                                );
                                whichRandomColor();

                                if (votesItem === itemInList.id) {
                                  return (
                                    <List.Item style={styles.listItem}>
                                      <Col
                                        xs={4}
                                        sm={3}
                                        md={2}
                                        style={styles.statusIcon}>
                                        {statusIcon}
                                      </Col>
                                      <Col
                                        xs={20}
                                        sm={21}
                                        md={22}
                                        style={styles.name}>
                                        {(itemInList?.name ?? '') +
                                          (itemInList?.last_name
                                            ? ' ' + itemInList.last_name
                                            : '')}
                                      </Col>
                                    </List.Item>
                                  );
                                } else {
                                  return <div />;
                                }
                              }}
                            />
                          );
                        } else {
                          return null;
                        }
                      })
                    ) : (
                      <div style={styles.separator} />
                    )}
                  </div>
                ))
              : null}
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <h3>{'Pregunta'}</h3>
              <p style={styles.question}>{question}</p>
              <h3>{'Fecha de Expiración'}</h3>
              <DatePicker
                onChange={onChangeDate}
                format={'DD-MM-YYYY'}
                showToday={false}
                locale={locale}
                style={styles.picker}
                placeholder="Fecha de Expiración"
                popupStyle={{zIndex: 3000}}
                defaultValue={expirationDate}
                disabled={!editMode}
              />
              {executivePrivilege && <h3>{'Vecindarios encuestados'}</h3>}

              {executivePrivilege && (
                <Select
                  mode="multiple"
                  disabled={true}
                  style={styles.picker}
                  dropdownStyle={{zIndex: 3000}}
                  onChange={onSelectHood}
                  defaultValue={neighborhoodIds}
                  placeholder={
                    hoods.length <= 0
                      ? 'Agregue vecindarios para habilitar'
                      : null
                  }>
                  {hoods.length > 0 &&
                    hoods.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.number ? item.number : 'Sin Nombre'}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <h3>{'Opciones'}</h3>
              <div style={styles.switchContainer}>
                <Switch
                  size="small"
                  style={styles.switch}
                  disabled={true}
                  checked={pollDetail?.isAnonymous}
                />
                <h4 style={styles.switchText}>{'Encuesta Anónima'}</h4>
              </div>

              <div style={styles.switchContainer}>
                <Switch
                  size="small"
                  style={styles.switch}
                  disabled={true}
                  checked={pollDetail?.hasOpenResults}
                />
                <h4 style={styles.switchText}>
                  {'Resultados visibles para otros usuarios'}
                </h4>
              </div>

              <div style={styles.switchContainer}>
                <Switch
                  size="small"
                  style={styles.switch}
                  disabled={true}
                  checked={pollDetail?.hasOtherAnswer}
                />
                <h4 style={styles.switchText}>
                  {'Permitir respuesta abierta'}
                </h4>
              </div>
              <h3 style={styles.results}>{'Resultados'}</h3>
              <PollGrapher choices={pollDetail?.choices ?? []} />
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default PollViewer;
